








































































































































































































































  import { debounceInput, isMobileScreenWidth } from '@/shared/helpers'
  import { CompanyVehicle, ICompanyVehicle } from '@/shared/model/companyVehicle.model'
  import { IUser, User } from '@/shared/model/user.model'
  import companyService from '@/shared/services/companyService'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import CompanyVehicleWizard from './CompanyVehicleWizard.vue'
  import CompanyVehicleDetails from './CompanyVehicleDetails.vue'

  export default Vue.extend({
    name: 'CompanyVehiclesTable',
    components: {
      CompanyVehicleWizard: () => import('./CompanyVehicleWizard.vue'),
      CompanyVehicleDetails: () => import('./CompanyVehicleDetails.vue'),
      ReportsTableByCompanyVehicle: () => import('../report/ReportsTableByCompanyVehicle.vue'),
    },

    props: {
      selectionMode: {
        type: Boolean,
        default: () => false,
      },
    },
    data () {
      return {
        options: {} as any,

        dialog: false,

        vehicleSearch: '',
        vehicleDebounce: undefined as number | undefined,

        selectedVehicle: new CompanyVehicle(),
        vehicleDetails: undefined as ICompanyVehicle | undefined,

        breadcrumbItems: [] as any[],
        isMobile: isMobileScreenWidth(),
      }
    },
    computed: {
      ...mapGetters({
        account: 'account',
        company: 'company',
        vehicles: 'companyVehicleStore/getList',
        totalVehicles: 'companyVehicleStore/getTotal',
      }),
      vehicleHeaders (): any {
        return [
          {
            text: this.$t('manufacturer'),
            value: 'manufacturer.name',
          },
          {
            text: this.$t('vehicle'),
            value: 'vehicle.name',
          },
          {
            text: this.$t('owner'),
            value: 'owner',
          },
          {
            text: this.$t('vin'),
            value: 'vin',
          },
          {
            text: this.$t('licence-plate'),
            value: 'licencePlate',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
    },
    watch: {
      $route: function () {
        this.breadcrumbItems = []
        this.getRouteData()
      },
      options: {
        async handler () {
          this.getAllVehiclesWithVDataTable()
        },
      },
      vehicleSearch: {
        async handler () {
          this.vehicleDebounce = debounceInput(this.vehicleDebounce, this.getAllVehiclesWithVDataTable)
        },
      },
      vehicles: {
        async handler () {
          this.getRouteData()
        },
      },
    },
    created () {
      companyService.getCompany(this.$store)
    },
    methods: {
      ...mapActions('companyVehicleStore', {
        updateVehicle: 'updateItem',
        deleteVehicle: 'deleteItem',
      }),
      async getAllVehiclesWithVDataTable () {
        if (this.company) {
          const query = {
            l: this.options.itemsPerPage,
            lo: this.options.page,
            sf: this.options.sortBy[0],
            so: this.options.sortDesc[0] ? 'desc' : 'asc',
            search: { search: this.vehicleSearch },
          }
          this.$store.dispatch('companyVehicleStore/getList', query)
        }
      },
      clickUpdateVehicle (item: ICompanyVehicle) {
        this.selectedVehicle = item
        this.dialog = true
      },
      clickDeleteVehicle (vehicle: ICompanyVehicle) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-vehicle-to')}
<strong>${vehicle.owner}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('tooltip.delete-vehicle').toString(),
          },
        ).then((res) => {
          if (res) {
            if (vehicle._id) {
              this.deleteVehicle({ id: vehicle._id, item: vehicle }).then(() => {
                this.getAllVehiclesWithVDataTable()
              })
            } else {
              console.error('Tried to delete vehicle without id, this should not happen')
            }
          }
        })
      },
      autocompleteFilter (item: any, queryText: string, itemText: string) {
        return item.name.toLowerCase().includes(queryText.toLowerCase())
      },
      handleWizardFinished () {
        this.getAllVehiclesWithVDataTable()
        this.dialog = false
      },
      clickAddVehicle () {
        this.selectedVehicle = new CompanyVehicle()
        this.dialog = true
      },
      clickVehicleDetails (companyVehicle: ICompanyVehicle) {
        this.$router.push(`/app/company-vehicles/${companyVehicle._id}`, () => {})
      },
      getRouteData () {
        const companyVehicleId = this.$route.params.id
        if (companyVehicleId) {
          const foundVehicle: any = this.vehicles.find((x: ICompanyVehicle) => x._id === companyVehicleId)
          if (foundVehicle && foundVehicle.owner) {
            this.vehicleDetails = foundVehicle
            this.breadcrumbItems = [
              {
                text: this.$t('my-vehicles'),
                to: '/app/company-vehicles',
                exact: true,
              },
              {
                text: `${foundVehicle.manufacturer ? foundVehicle.manufacturer.name : this.$t('no-manufacturer')} 
                ${foundVehicle.vehicle ? foundVehicle.vehicle.name : this.$t('no-vehicle')} - ${foundVehicle.owner}`,
              },
            ]
          } else {
            this.vehicleDetails = undefined
          }
        } else {
          this.vehicleDetails = undefined
        }
      },
    },
  })
