var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"vehicles-tables","fluid":"","tag":"section"}},[(_vm.breadcrumbItems.length)?_c('v-breadcrumbs',{attrs:{"divider":"/","items":_vm.breadcrumbItems}}):_vm._e(),(_vm.company && !_vm.vehicleDetails)?_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('base-material-card',{staticClass:"py-3",attrs:{"color":"indigo","icon":"mdi-car","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('my-vehicles'))+" ")])]},proxy:true}],null,false,2110603812)},[_c('v-text-field',{staticClass:"ml-auto responsive-table-search-input",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},model:{value:(_vm.vehicleSearch),callback:function ($$v) {_vm.vehicleSearch=$$v},expression:"vehicleSearch"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"responsive-table",attrs:{"options":_vm.options,"server-items-length":_vm.totalVehicles ? _vm.totalVehicles : _vm.vehicles.length,"headers":_vm.vehicleHeaders,"items":_vm.vehicles,"footer-props":{
            'items-per-page-options': [10, 20, 50, 100],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',{attrs:{"data-label":_vm.vehicleHeaders[0].text}},[_vm._v(" "+_vm._s(row.item.manufacturer ? row.item.manufacturer.name : '-')+" ")]),_c('td',{attrs:{"data-label":_vm.vehicleHeaders[1].text}},[_vm._v(" "+_vm._s(row.item.vehicle ? row.item.vehicle.name : '-')+" ")]),_c('td',{attrs:{"data-label":_vm.vehicleHeaders[2].text}},[_vm._v(" "+_vm._s(row.item.owner)+" ")]),_c('td',{attrs:{"data-label":_vm.vehicleHeaders[3].text}},[_vm._v(" "+_vm._s(row.item.vin)+" ")]),_c('td',{attrs:{"data-label":_vm.vehicleHeaders[4].text}},[_vm._v(" "+_vm._s(row.item.licencePlate)+" ")]),_c('td',{staticClass:"text-right",attrs:{"data-label":_vm.vehicleHeaders[5].text}},[(!_vm.selectionMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"indigo"},on:{"click":function($event){return _vm.clickVehicleDetails(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-information ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.show-vehicle')))])]):_vm._e(),(!_vm.selectionMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.clickUpdateVehicle(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pen ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit-vehicle')))])]):_vm._e(),(!_vm.selectionMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"#db3236"},on:{"click":function($event){return _vm.clickDeleteVehicle(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-vehicle')))])]):_vm._e(),(_vm.selectionMode && row.item.manufacturer && row.item.vehicle)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.$emit('vehicleSelected', row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-right ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.select-for-report')))])]):(_vm.selectionMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"opacity":"0.6"},attrs:{"small":"","color":"grey"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-right ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.vehicle-details-missing')))])]):_vm._e()],1)])]}}],null,false,3060349972)}),_c('v-divider'),_c('v-row',{staticClass:"mx-auto py-6",attrs:{"justify":"end","align":"center"}},[(!_vm.selectionMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","fab":"","small":""},on:{"click":_vm.clickAddVehicle}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,13957448)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add-vehicle')))])]):_vm._e()],1)],1)],1)],1):(_vm.company && _vm.vehicleDetails)?_c('div',[_c('company-vehicle-details',{attrs:{"company-vehicle":_vm.vehicleDetails}}),_c('reports-table-by-company-vehicle',{attrs:{"company-vehicle-id":_vm.vehicleDetails._id,"vehicle-has-valid-data":_vm.vehicleDetails.vehicle && _vm.vehicleDetails.manufacturer}})],1):_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('p',{staticStyle:{"font-size":"1.3em"}},[_vm._v(" "+_vm._s(_vm.$t('dialog.you-are-not-a-member-of-a-company'))+" ")])])],1),(_vm.dialog && _vm.selectedVehicle)?_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('company-vehicle-wizard',{attrs:{"selected-company-vehicle":_vm.selectedVehicle},on:{"wizardfinished":_vm.handleWizardFinished}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }